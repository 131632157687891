// useState is a hook that allows you to add a state variable to the component
// useEffect is a hook that lets you synchronize a component with an external system
// import { useState, useEffect } from 'react';
// BrowserRouter stores the current location in the browser's address bar using clean URLs
// and navigates using the browser's built-in history stack
// Routes will match a set of child routes from the current location
// Switch renders the first child <Route> or <Redirect> that matches the location
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// import { useDispatch, useSelector } from 'react-redux'

// import { authenticate } from './store/session';
import Splashpage from './components/splashpage/Splashpage';
import Contact from './components/contact/Contact';
import Forms from './components/forms/Forms';
import OurPractice from './components/ourpractice/OurPractice';
import Services from './components/services/Services';
import Telemedicine from './components/telemedicine/Telemedicine';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import AdminLogin from './components/adminlogin/AdminLogin';
import AdminPortal from './components/adminportal/AdminPortal';
import AdminBanner from './components/adminlogin/AdminBanner';
import ApptInfo from './components/apptinfo/ApptInfo';

function App() {
  // const [currentUserIsLoaded, setCurrentUserIsLoaded] = useState(false);
  // const dispatch = useDispatch()
  // const currentUser = useSelector(state => state.session.user);

  // useEffect(() => {
  //   (async () => {
  //     await dispatch(authenticate())
  //     setCurrentUserIsLoaded(true);
  //   })();
  // }, [dispatch])

  // if (!currentUserIsLoaded) return null

  // const Home = () => {
  //   if (currentUser) {
  //     return (
  //       <>
  //         {/* Splashpage component */}
  //       </>
  //     )
  //   }
  // }
  // Additional Routes and components will be added below
  return (
    <BrowserRouter>
      <Header />
      <AdminBanner />
      <Routes>
        <Route path='/' element={<Splashpage />}></Route>
        <Route path='/contact' element={<Contact />}></Route>
        <Route path='/forms' element={<Forms />}></Route>
        <Route path='/ourpractice' element={<OurPractice />}></Route>
        <Route path='/services' element={<Services />}></Route>
        <Route path='/telemedicine' element={<Telemedicine />}></Route>
        <Route path='/adminlogin' element={<AdminLogin />}></Route>
        <Route path='/adminportal' element={<AdminPortal />}></Route>
        {/* <Route path='/apptinfo' element={<ApptInfo />}></Route> */}


      </Routes>
      <Footer />
    </BrowserRouter>
  )
}

export default App;